import storage from './storage'
import { metadata, PhoneNumberUtil } from "google-libphonenumber";
// import { _ } from 'core-js';
// import { _ } from 'core-js';
import _ from 'lodash'
import { Base64 } from 'js-base64'
import { hex_sha1 } from "./sha1";
import moment from 'moment'


const checkEmail = (value) => {
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(value);
}
const checkPhoneMobile = (icode, mobile) => {
    mobile = mobile.replace(/\s+/g, '');
    if (typeof icode == 'string') {
        icode = icode.replace('+', '')
        icode = parseInt(icode)
    }
    /**
     * 2023.12.07
     * select case country code
     * case 1:
     * Digits 10
     * Prefix: Any

    * case 852
    * Digits: 8
    * Prefix: 2,3,4,5,6,7,9

    * case 65
    * Digits: 8
    * Prefix: 3,6,8,9

    * case 66:
    * Digits: 9 or 10
    * Prefix: 02,03,04,05,06,08,09,2,3,4,5,6,7,8,9

    * case 44:
    * Digits: 10 or 11
    * Prefix: 01,02,07,1,2,7

    * case 86
    * Digits 11
    * Prefix: 1

    * case else:
    * Digits: >=7 and <=12
    * Prefix: Any

    */
    let regex = /^[0-9]{7,12}$/;

    switch (icode) {
        case 1:
            regex = /^[0-9]{10}$/;
            break;
        case 86:
            regex = /^1[0-9]{10}$/;
            break;
        case 852:
            regex = /^[0-9]{8}$/;
            // regex = /^(2|3|4|5|6|7|9)[0-9]{7}$/;
            break;
        case 65:
            regex = /^[0-9]{8}$/;
            // regex = /^(3|6|8|9)[0-9]{7}$/;
            break;
        case 66:
            regex = /^[0-9]{9,10}$/;
            break;
        case 44:
            regex = /^[0-9]{10,11}$/;
            break;

    }
    return regex.test(mobile);

    /**
     * 2023.08.24
     * phone number validation rules
     * "65", "852" 8 digit number
     * "86" 11 digit number
     * "66","33", "61", "62",  "82", "886" 9 or 10 digit number
     * "1", "44","49", "81", "91" 10 or 11 digit number
     * all other country codes >=7 digit and <=12 digit number
     */
    //if 86  then 11 digits
    // if (_.includes([86], icode)) {
    //     return /^1[0-9]{10}$/.test(mobile);
    // }

    // // else if 1 OR 44 OR 49 OR 81 OR 91 then 10,11 digits
    // else if (_.includes([1, 44, 49, 81, 91], icode)) {
    //     return /^[0-9]{10,11}$/.test(mobile);
    // }

    // //else if 33 OR 61 OR 62 OR 66 OR 82 OR 886 then 9,10 digits
    // else if (_.includes([33, 61, 62, 66, 82, 886], icode)) {
    //     return /^[0-9]{9,10}$/.test(mobile);
    // }

    // //else if 65 OR 852 then 8 digits
    // else if (_.includes([65, 852], icode)) {
    //     return /^[0-9]{8}$/.test(mobile);
    // } else {
    //     return /^[0-9]{7,12}$/.test(mobile);
    // }
}
const checkMobile = (mobile) => {

    return /^[0-9]{6,15}$/.test(mobile);
}

const checkMobile2 = (code, mobile) => {
    // console.log('checkMobile2', typeof code, code, mobile)

    // if (code == 852) {
    //     return /^[5689]{1}\d{7}$/.test(mobile)
    // }

    if (typeof code == 'string' && code.indexOf('+') > -1) {
        code = code.replace('+', '');
    }
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        // console.log('dd', checkMobile(mobile))
        if (phoneUtil && metadata.countryCodeToRegionCodeMap[code]) {
            const number = phoneUtil.parseAndKeepRawInput(mobile, metadata.countryCodeToRegionCodeMap[code][0]);
            const isValite = phoneUtil.isValidNumber(number);
            // console.log('dd', isValite)
            return isValite;
        }

        return checkMobile(mobile)
    } catch (err) {
        console.log('errrrrr', err.message)
        return false;
    }


}

const getDateStr = (y, m, d) => {

    m = m + 1;

    return {
        "en": `${d < 10 ? '0' + d : d}/${m < 10 ? '0' + m : m}/${y}`,
        "cn": `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`,
    };
}
const calcDays = (year, month) => {
    if (month == 2) {
        if (
            (year % 4 == 0 && year % 100 != 0) ||
            (year % 400 == 0 && year % 4000 != 0)
        ) {
            return 29;
        } else {
            return 28;
        }
    } else {
        if (month == 4 || month == 6 || month == 9 || month == 11) {
            return 30;
        } else {
            return 31;
        }
    }
}

const parseDate = (dateStr) => {
    let dateArr = { yy: "", mm: "", dd: "" };

    if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateStr)) {
        let rs = dateStr.match(/(^|-)\d{1,4}/g);
        // console.log("res", rs);
        dateArr.yy = rs[0];
        dateArr.mm = rs[1].replace(/-/g, "");
        dateArr.dd = rs[2].replace(/-/g, "");
    }
    return dateArr;
}

const setLoginData = (response) => {
    if (_.has(response, "client_id")) {
        storage.set("user", {
            id: _.get(response, "client_id", ""),
            userId: _.get(response, "client_id", ""),
            token: _.get(response, "access_token", ""),
            isEmployee: false,
            isCrm: true,
        });
        storage.set("userInfo", response);
        storage.set("token", _.get(response, "access_token", ""));
        storage.setAuthTag();

    }
}
const parseFirstLoadPage = (response) => {
    let refv = "";
    if (response.is_new_user == 1) {
        refv = "signup";
    }
    let route_name = '';
    let query = null
    let params = null
    let type = 'route'

    //city_url
    if (_.get(response, "city_url")) {
        storage.set('ls_city', _.get(response, "city_url"))
    }

    let firstLoadPage = _.get(response, "first_load_page");
    // let uncompleted_field = _.get(response, "uncompleted_field");
    let unread_approved_agreement = _.get(response, "unread_approved_agreement");
    if (unread_approved_agreement > 0) {
        route_name = 'upgrade-congrats';
        query = { ref: "approved_agreement" }

        return {
            route_name, query, type
        }
    }

    // if (uncompleted_field) {
    //     route_name = 'reg-step'
    //     query = {
    //         step: uncompleted_field.profile_step,
    //         ref: refv
    //     }
    // }

    if (firstLoadPage.indexOf("profile_step") > -1) {
        route_name = 'reg-step'
        let stepV = firstLoadPage.replace("profile_step", "")

        if (_.get(response, "membership_level_id", 0) > 1 && stepV != 'email' && stepV != 'idphoto' && stepV != 'idphoto_dis') {
            stepV = 'miss'//firstLoadPage.replace("profile_step", "")
        }

        query = {
            step: stepV,
            ref: refv,
            type
        }
    }
    else {
        switch (firstLoadPage) {
            case 'book_consultation':
                route_name = 'book-consultation'
                break;
            case 'op_onboarding':
                let stepO = _.get(response, "uncompleted_field_op.profile_step");

                route_name = 'onboarding-op-free';
                if (stepO) {
                    params = {
                        step: stepO
                    }
                }
                // let cityUrl = _.get(response, "city_url", 'hong-kong');
                // let uLang = _.get(response, "lang_code", 'en_US');


                // let lang = uLang.replace('_', '-');
                // if (lang.indexOf('en') > -1) {
                //     lang = 'en'
                // }

                // storage.set("user-goal", {
                //     i: _.get(response, "country_phone_code", ''),
                //     m: _.get(response, "phone_number", ''),
                // });


                // // route_name = `https://www.lovestruck.com/${cityUrl}/${lang}/screening/user-goal`
                // route_name = `https://www.lovestruck.com/${cityUrl}/${lang}/screening`
                // type = 'link'

                break;
            case "search":
            case "match":
                route_name = 'matchmaking-new-matches';
                break;
            case "call_back":
            case "match_free_cb":
                route_name = 'matchmaking-callback';
                break;
            case "op_matches":
                route_name = 'op-match-list';
                break;
            case "free_page":
                route_name = 'matchmaking-index';
                break;
            // case "elites":
            //     route_name = 'elite-list';
            //     break;
            case "client_chat":
                route_name = 'client-chat';
                break;
            case 'renew_payment':
                route_name = 'renew-payment';
                break;
            default:
                route_name = 'op-match-list';
                break;
        }
    }

    return {
        route_name, query, type, params
    }
}

const renewStarterCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 21,
            title: self.$t('verified_matches'),
            desc: self.$t('verified_matches_desc'),
            icon: `${cdnBase}gold/verified_matches_large_gold.png`,
            // icon: `${cdnBase}starter/verified_matches_large_starter.png`,
        },
        {
            index: 22,
            title: self.$t('start_shats'),
            desc: self.$t('start_shats_desc'),
            // icon: `${cdnBase}starter/chat_large_starter.png`,
            icon: `${cdnBase}gold/chat_large_gold.png`,
        },
        {
            index: 23,
            title: self.$t('date_concierge'),
            desc: self.$t('date_concierge_desc'),
            // icon: `${cdnBase}starter/concierge_large_starter.png`,
            icon: `${cdnBase}gold/concierge_large_gold.png`,
        },
    ]

}

const renewPlusCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 31,
            title: self.$t('verified_matches'),
            desc: self.$t('verified_matches_desc'),
            // icon: `${cdnBase}gold/verified_matches_large_gold.png`,
            icon: `${cdnBase}starter/verified_matches_large_starter.png`,
        },
        {
            index: 32,
            title: self.$t('start_shats'),
            desc: self.$t('start_shats_desc'),
            // icon: `${cdnBase}gold/chat_large_gold.png`,
            icon: `${cdnBase}starter/chat_large_starter.png`,
        },
        {
            index: 33,
            title: self.$t('date_concierge'),
            desc: self.$t('date_concierge_desc'),
            // icon: `${cdnBase}gold/concierge_large_gold.png`,
            icon: `${cdnBase}starter/concierge_large_starter.png`,
        },
        {
            index: 34,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            icon: `${cdnBase}starter/perfect_match_filters_large_plus.png`,
        },
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}starter/privacy_large_plus.png`,
        },
    ]

}

const renewDiamondCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 41,
            title: self.$t('verified_matches'),
            desc: self.$t('verified_matches_desc'),
            icon: `${cdnBase}diamond/verified_matches_large_diamond.png`,
        },
        {
            index: 42,
            title: self.$t('start_shats'),
            desc: self.$t('start_shats_desc'),
            icon: `${cdnBase}diamond/chat_large_diamond.png`,
        },
        {
            index: 43,
            title: self.$t('date_concierge'),
            desc: self.$t('date_concierge_desc'),
            icon: `${cdnBase}diamond/concierge_large_diamond.png`,
        },
        {
            index: 44,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            icon: `${cdnBase}diamond/perfect_match_filters_large_diamond.png`,
        },
        {
            index: 45,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}diamond/privacy_large_diamond.png`,
        },
        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
        },
        {
            index: 47,
            title: self.$t('match_with_elites'),
            desc: self.$t('match_with_elites_desc'),
            icon: `${cdnBase}diamond/elites_large_diamond.png`,
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}diamond/match_boost_large_diamond.png`,
        },
    ]

}

const freePlusCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 31,
            title: self.$t('verified_matches'),
            desc: self.$t('verified_matches_desc'),
            // icon: `${cdnBase}payment/verified_matches_small_plus.png`,
            icon: 'fa-regular fa-user-check'
        },
        {
            index: 32,
            title: self.$t('start_shats'),
            desc: self.$t('start_shats_desc'),
            // icon: `${cdnBase}payment/start_chats_small_plus.png`,
            icon: 'fa-regular fa-comment'
        },
        {
            index: 33,
            title: self.$t('date_concierge'),
            desc: self.$t('date_concierge_desc'),
            // icon: `${cdnBase}payment/date_concierge__small_plus.png`,
            icon: 'fa-regular fa-calendar-circle-user'
        },
        {
            index: 34,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            // icon: `${cdnBase}payment/perfect_match_filters_small_plus.png`,
            icon: 'fa-regular fa-sliders'
        },
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            // icon: `${cdnBase}payment/privacy_mode_small_gold.png`,
            icon: 'fa-regular fa-lock-keyhole'
        },
    ]

}

const freeStarterCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 21,
            title: self.$t('verified_matches'),
            desc: self.$t('verified_matches_desc'),
            // icon: `${cdnBase}payment/verified_matches_small_starter.png`,
            icon: 'fa-regular fa-user-check'
        },
        {
            index: 22,
            title: self.$t('start_shats'),
            desc: self.$t('start_shats_desc'),
            // icon: `${cdnBase}payment/start_chats_small_starter.png`,
            icon: 'fa-regular fa-comment'
        },
        {
            index: 23,
            title: self.$t('date_concierge'),
            desc: self.$t('date_concierge_desc'),
            // icon: `${cdnBase}payment/date_concierge_small_starter.png`,
            icon: 'fa-regular fa-calendar-circle-user'
        },
    ]

}
const mePageCarousels = (self, membership_level_id) => {
    // let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/package_icons";
    // let getDia = self.$t('get_diamond_txt')
    let getPlus = self.$t('get_plus')
    let get_matchmaking = self.$t('get_matchmaking')

    let verified_matches = {
        index: 35,
        title: self.$t('verified_matches'),
        desc: self.$t('verified_matches_desc'),
        // icon: `${cdnBase}payment/verified_matches_small_plus.png`,
        icon: 'fa-light fa-user-check',
        btn: {
            type: 'plus',
            text: getPlus
        }
    }
    let privacy_mode = {
        index: 46,
        title: self.$t('dia_privacy_mode'),
        desc: self.$t('dia_privacy_mode_desc'),
        // icon: `${cdnBase}payment/privacy_mode_small_gold.png`,
        icon: 'fa-light fa-lock-keyhole',
        btn: {
            type: 'plus',
            text: getPlus
        }
    }

    let personal_matchmaker = {
        index: 46,
        title: self.$t('dia_personal_matchmaker'),
        desc: self.$t('dia_personal_matchmaker_desc'),
        // icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
        icon: 'fa-light fa-user-magnifying-glass',
        btn: {
            type: 'matchmaking',
            text: get_matchmaking
        }
    }

    let priority_matching = {
        index: 48,
        title: self.$t('priority_matching'),
        desc: self.$t('priority_matching_desc'),
        // icon: `${cdnBase}/priority_diamond.png`,
        icon: 'fa-light fa-rocket-launch',
        btn: {
            type: 'matchmaking',
            text: get_matchmaking
        }
    }

    // let match_filter = {
    //     index: 34,
    //     title: self.$t('dia_perfect_match'),
    //     desc: self.$t('dia_perfect_match_desc'),
    //     icon: `${cdnBase}/filters_diamond.png`,
    //     btn: {
    //         type: 'diamond',
    //         text: getDia
    //     }
    // }
    let match_elites = {
        index: 47,
        title: self.$t('match_with_elites'),
        desc: self.$t('match_with_elites_desc'),
        // icon: `${cdnBase}/elites_diamond.png`,
        icon: 'fa-light fa-user-group-crown',
        btn: {
            type: 'matchmaking',
            text: get_matchmaking
        }
    }
    

    if (membership_level_id == 2) {
        return [verified_matches, privacy_mode, personal_matchmaker, match_elites, priority_matching];
    } else if (membership_level_id == 3) {
        return [personal_matchmaker, match_elites, priority_matching];
    }

    return [];

    // let profile_perfecter = {
    //     index: 50,
    //     title: self.$t('profile_perfecter'),
    //     desc: self.$t('profile_perfecter_desc'),
    //     icon: `${cdnBase}/profile_diamond.png`,
    //     btn: {
    //         type: 'diamond',
    //         text: getDia
    //     }
    // }
    // let dating_passport = {
    //     index: 51,
    //     title: self.$t('dating_passport'),
    //     desc: self.$t('dating_passport_desc'),
    //     icon: `${cdnBase}/passport_diamond.png`,
    //     btn: {
    //         type: 'diamond',
    //         text: getDia
    //     }
    // }
}
//v6.2
const membershipColor = (name) => {

    let color_map = {
        'free': '',
        'guest': '#e61e5a',
        'starter': '#169f46',
        'silver': '#c0c0c0',
        'gold': '#ffc000',
        'diamond': '#0abab5',
        'vip': '#ffffff',
        'super_vip': '#60497a',
    };

    return _.get(color_map, name)
}
const guest2StarterCarousels = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/package_icons";

    return [
        {
            index: 34,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            icon: `${cdnBase}/match_filters_starter.png`,
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}/priority_starter.png`,
        }
    ];
}
const starter2GoldCarousels = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/package_icons";

    return [
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}/privacy_gold.png`,
        },
        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}/matchmaker_gold.png`,
        },
        // {
        //     index: 34,
        //     title: self.$t('dia_perfect_match'),
        //     desc: self.$t('dia_perfect_match_desc'),
        //     icon: `${cdnBase}/match_filters_starter.png`,
        // },
        // {
        //     index: 48,
        //     title: self.$t('priority_matching'),
        //     desc: self.$t('priority_matching_desc'),
        //     icon: `${cdnBase}/privacy_mode_starter.png`,
        // },
        {
            index: 50,
            title: self.$t('profile_perfecter'),
            desc: self.$t('profile_perfecter_desc'),
            icon: `${cdnBase}/profile_gold.png`,
        },
        {
            index: 51,
            title: self.$t('dating_passport'),
            desc: self.$t('dating_passport_desc'),
            icon: `${cdnBase}/passport_gold.png`,
        }
    ];
}
const silver2GoldCarousels = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/package_icons";

    return [
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}/privacy_gold.png`,
        },
        // {
        //     index: 46,
        //     title: self.$t('dia_personal_matchmaker'),
        //     desc: self.$t('dia_personal_matchmaker_desc'),
        //     icon: `${cdnBase}/matchmaker_gold.png`,
        // },
        {
            index: 51,
            title: self.$t('dating_passport'),
            desc: self.$t('dating_passport_desc'),
            icon: `${cdnBase}/passport_gold.png`,
        }
    ];
}

const meStarterCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    let getPlus = self.$t('get_plus')
    // let getGold = self.$t('get_gold')
    // let getDia = self.$t('get_diamond_txt')
    let getDia = self.$t('get_matchmaking')
    //payment/
    return [
        {
            index: 34,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            icon: `${cdnBase}starter/perfect_match_filters_large_plus.png`,
            btn: {
                type: 'plus',
                text: getPlus
            }
        },
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}starter/privacy_large_plus.png`,
            btn: {
                type: 'plus',
                text: getPlus
            }
        },
        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
        {
            index: 47,
            title: self.$t('match_with_elites'),
            desc: self.$t('match_with_elites_desc'),
            icon: `${cdnBase}diamond/elites_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}diamond/match_boost_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
    ]
}

const meGoldCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";

    // let getDia = self.$t('get_diamond_txt')
    let getDia = self.$t('get_matchmaking')
    //payment/
    return [

        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
        {
            index: 47,
            title: self.$t('match_with_elites'),
            desc: self.$t('match_with_elites_desc'),
            icon: `${cdnBase}diamond/elites_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}diamond/match_boost_large_diamond.png`,
            btn: {
                type: 'diamond',
                text: getDia
            }
        },
    ]
}
const upgradeGoldCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    //payment/
    return [
        {
            index: 34,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            // icon: `${cdnBase}upgrade/perfect_match_filters_large_gold.png`,
            icon: `${cdnBase}starter/perfect_match_filters_large_plus.png`,
        },
        {
            index: 35,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            // icon: `${cdnBase}upgrade/privacy_large_gold.png`,
            icon: `${cdnBase}starter/privacy_large_plus.png`,
        },
        {
            index: 36,
            title: self.$t('all_starter_features'),
            desc: self.$t('all_starter_features_desc'),
            // icon: `${cdnBase}upgrade/icons_starter_incl_3.png`,
            icon: `${cdnBase}payment/icons_starter_incl.png`,
        },
    ]
}
const upgradeDiamondCarousel = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    return [

        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
        },
        {
            index: 47,
            title: self.$t('match_with_elites'),
            desc: self.$t('match_with_elites_desc'),
            icon: `${cdnBase}diamond/elites_large_diamond.png`,
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}diamond/match_boost_large_diamond.png`,
        },
        {
            index: 44,
            title: self.$t('dia_perfect_match'),
            desc: self.$t('dia_perfect_match_desc'),
            icon: `${cdnBase}diamond/perfect_match_filters_large_diamond.png`,
        },
        {
            index: 45,
            title: self.$t('dia_privacy_mode'),
            desc: self.$t('dia_privacy_mode_desc'),
            icon: `${cdnBase}diamond/privacy_large_diamond.png`,
        },
        {
            index: 49,
            title: self.$t('all_starter_features'),
            desc: self.$t('all_starter_features_desc'),
            icon: `${cdnBase}payment/icons_starter_incl.png`,
        },
    ]
}
const upgradeDiamondCarouselForGold = (self) => {
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/";
    return [

        {
            index: 46,
            title: self.$t('dia_personal_matchmaker'),
            desc: self.$t('dia_personal_matchmaker_desc'),
            icon: `${cdnBase}diamond/matchmaker_large_diamond.png`,
        },
        {
            index: 47,
            title: self.$t('match_with_elites'),
            desc: self.$t('match_with_elites_desc'),
            icon: `${cdnBase}diamond/elites_large_diamond.png`,
        },
        {
            index: 48,
            title: self.$t('priority_matching'),
            desc: self.$t('priority_matching_desc'),
            icon: `${cdnBase}diamond/match_boost_large_diamond.png`,
        },

        {
            index: 49,
            title: self.$t('all_gold_features'),
            desc: self.$t('all_gold_features_desc'),
            icon: `${cdnBase}payment/icons_plus_incl.png`,
        },
    ]
}

const getMembershipNameById = (membership_level_id) => {
    let map = {
        0: 'free',
        1: 'free',
        2: 'starter',
        3: 'plus',
        4: 'diamond',
        5: 'diamond',
        6: 'diamond',
        7: 'diamond',
        8: 'diamond',
        9: 'diamond',
    }

    // let name = _.get(map, membership_level_id)
    let name = map[membership_level_id]

    return name ? name : 'free'
}
const getMembershipPhotoById = (membership_level_id) => {
    let name = getMembershipNameById(membership_level_id)
    let photos = membershipPhoto()
    // let photo = _.get(photos, name)
    let photo = photos[name]

    return photo ? photo : ''
}
const membershipTextPhoto = () => {
    return {
        // 'free': "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_grey.svg",
        'starter': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_starter_text.png',
        'gold': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_gold_text.png',
        'plus': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_plus_text.png',
        'diamond': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_matchmaking_text.png',
        // 'diamond': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_diamond_text.png',
    }
}
const membershipLogoTextPhoto = () => {
    return {
        'starter': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_starter_logo_pay.png',
        'gold': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_gold_text.png',
        'plus': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_plus_logo_pay.png',
        'diamond': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_matchmaking_logo_pay.png',
        // 'diamond': 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_diamond_logo_pay.png',
    }
}
const membershipPhoto = () => {
    return {
        free: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_grey.svg",
        // basic:
        //     "https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/LS_BASIC.png",
        // starter: 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_starter_logo.png',
        // starter: 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_starter_logo.png',
        // starter: 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/upgrade/level_starter_logo_upgrade.png',
        starter: 'https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Lovestruck-Starter-Lockup@3x.png',
        // plus: 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/level_plus_logo.png',
        // plus: 'https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/upgrade/level_plus_logo_upgrade.png',
        plus: 'https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Lovestruck-Plus-Lockup@3x.png',
        // gold: "https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/LS_GOLD.png",
        // gold: "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_gold_logo.png",
        // platinum:
        //     "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/lovestruck_platinum.png",
        // diamond:
        //     "https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/LS_DIAMOND.png",
        // diamond:"https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_diamond_logo.png",
        // diamond: "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/upgrade/level_diamond_logo_upgrade.png",
        // diamond: "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/upgrade/level_matchmaking_logo_upgrade.png",
        diamond: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Lovestruck-Matchmaking-Lockup@3x.png",
        matchmaking: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Lovestruck-Matchmaking-Lockup@3x.png",
    }
}
const lsLogos = () => {
    return {
        free: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_grey.svg",
        basic:
            "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_red.svg",
        gold: "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_gold.svg",
        diamond:
            "https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_logo_diamond.svg",
    }
}
const divideNumber = (dividend, divisor, show_decimals) => {
    // console.log(dividend/divisor)
    let res = dividend / divisor
    let n = 0;

    if (show_decimals) {
        res = Math.floor(res * 100) / 100
        n = res.toFixed(2);
    } else {
        n = Math.floor(res)
    }
    // console.log('nn=', n);

    return n;
}
const currenyPriceFormat = (value, show_decimals) => {
    if (!value) {
        return value;
    }
    let parts = value.toString().split(".");
    // console.log('parts', parts, show_decimals)
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // console.log('parts2', parts, show_decimals)
    if (show_decimals == 1 && parts[1]) {
        parts[1] = parts[1].substr(0, 2)
        if (parts[1].length == 1) {
            parts[1] = `${parts[1]}0`
        }
        if (parts.length == 2) {
            return parts.join(".");
        } else {
            return `${parts[0]}.00`
        }

    } else {
        return parts[0];
    }

}
const setLeftSec = (leftSec) => {

    if (isNaN(leftSec) && leftSec <= 0) {
        return { h: '', m: '', s: '' };
    }
    let hours = 0;
    // if (showHour) {
    hours = Math.floor(leftSec / 3600);
    // }
    let minutes = Math.floor((leftSec - hours * 3600) / 60);
    let sec = leftSec - hours * 3600 - minutes * 60;

    // let str = `${minutes >= 10 ? minutes : "0" + minutes}:${
    //   sec >= 10 ? sec : "0" + sec
    // }`;

    // if (showHour) {
    //   str = `${hours >= 10 ? hours : "0" + hours}:${str}`;
    // }

    //   if (hours < 0) {
    //     console.log("con", hours, minutes, sec, str);
    //   }

    return { h: `${hours >= 10 ? hours : "0" + hours}`, m: `${minutes >= 10 ? minutes : "0" + minutes}`, s: `${sec >= 10 ? sec : "0" + sec}` };
    //   console.log("left sec: ", str);
}
const device = {
    isAndroid: function () {
        if (/Linux/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent.toLowerCase())) {
            return true;
        }
        return false;
    }
}

const base64 = {
    encode: function (str) {
        return Base64.encode(str);
    },
    decode: function (str) {
        return Base64.decode(str);
    }
}

const lshash = {
    keyStr: 'S1d3aUImZ0xIOWNqZUxQbw',
    encode: function (str) {
        let self = this;
        str = `${str}`
        let key = hex_sha1(Base64.decode(self.keyStr))
        //            console.log('key', key)
        let strLen = `${str}`.length;
        let keyLen = key.length;
        let j = 0;
        let crypttext = '';

        //            console.log('strLen=', strLen, 'keyLen=', keyLen)
        for (let i = 0; i < strLen; i++) {
            let ordStr = str.substr(i, 1).charCodeAt();
            //                console.log(ordStr)
            if (j == keyLen) {
                j = 0;
            }
            let ordKey = key.substr(j, 1).charCodeAt();
            j++;
            //                let n16 = (ordStr + ordKey).toString(16);
            let n36 = (ordStr + ordKey).toString(36);
            let newStr = n36.split('').reverse().join("")
            //                console.log(ordStr + ordKey, n16, n36)
            crypttext = `${crypttext}${newStr}`

        }
        //            console.log('crypttext=', crypttext)
        return crypttext;
    },
    decode: function (str) {
        let self = this;
        let key = hex_sha1(Base64.decode(self.keyStr))
        //            console.log('key', key)
        let strLen = `${str}`.length;
        let keyLen = key.length;
        let j = 0;
        let crypttext = '';

        for (let i = 0; i < strLen; i += 2) {

            let part = str.substr(i, 2).split('').reverse().join("")
            let n10 = parseInt(part, 36)
            //                console.log(part, 'n10=', n10)
            if (j == keyLen) {
                j = 0;
            }
            let ordKey = key.substr(j, 1).charCodeAt();
            //                console.log('ordKey=', ordKey)
            j++;
            let s = String.fromCodePoint((n10 - ordKey));
            crypttext = `${crypttext}${s}`
        }
        //            console.log('crypttext=', crypttext)
        return crypttext;
    }
}
const cityShortName = (city_url) => {
    return _.get({
        'hong-kong': 'hk',
        'hongkong': 'hk',
        'bangkok': 'bkk',
        'london': 'uk',
        'singapore': 'sg',
    }, city_url, 'hk')
}
const prettyChatTime = (value, showTime = false) => {
    let result = '';
    let reDate = null
    if (value) {
        var date_now = new Date();
        let date = new Date(value * 1000);
        let diff_s = Math.abs((date_now.getTime() - date.getTime()) / 1000);
        let one_day_s = 24 * 3600;
        let diff_d = Math.ceil(diff_s / one_day_s);
        let curLang = storage.get('cur-lang')
        let Yesterday = 'Yesterday';
        let momentLocale = 'en'
        if (curLang == 'zh_CN') {
            momentLocale = 'zh-cn'
            Yesterday = '昨天';
        } else if (curLang == 'zh_HK') {
            momentLocale = 'zh-hk'
            Yesterday = '昨天';
        } else if (curLang == 'th') {
            momentLocale = 'th'
            Yesterday = 'เมื่อวานนี้';
        }
        moment.locale(momentLocale)
        reDate = moment(date)
        if (diff_s < one_day_s) {
            result = reDate.format("hh:mm");
            showTime = false
        } else if (diff_s >= one_day_s && diff_d >= 1 && diff_d < 2) {
            result = Yesterday
        } else if (diff_d >= 2 && diff_d <= 6) {
            result = reDate.format('dddd')
            // result = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()]
        } else if (diff_d >= 7) {
            if (date_now.getFullYear() == date.getFullYear()) {
                result = reDate.format(momentLocale == 'zh-cn' ? 'MM月DD日' : 'DD-MM')
                // result = moment(date).format("MM/DD");;
            } else {
                result = reDate.format(momentLocale == 'zh-cn' ? 'YYYY年MM月DD日' : 'DD-MM-YYYY')
                // result = moment(date).format("YYYY/MM/DD");
            }
        }
    }
    if (showTime && reDate) {
        return `${result} ${reDate.format("hh:mm")}`
    } else {
        return result;
    }

}
const diamondCarouselItems = (self, allGold = false) => {
    //diamond starter (membership_level_id=4)
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";
    let getDiamondTxt = self.$t("get_diamond_txt");

    let carousels = [
        //diamond
        {
            index: 41,
            title: self.$t("dia_personal_matchmaker"),
            desc: self.$t("dia_personal_matchmaker_desc"),
            icon: `${cdnBase}payment/new_PersonalMatchmaker_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },
        // {
        //     index: 42,
        //     title: self.$t("match_boost"),
        //     desc: self.$t("match_boost_desc"),
        //     icon: `${cdnBase}payment/new_MatchBoost_diamond.png`,
        //     btn: {
        //         text: getDiamondTxt,
        //         type: "diamond",
        //     },
        // },
        {
            index: 45,
            title: self.$t("match_with_elites"),
            desc: self.$t("match_with_elites_desc"),
            icon: `${cdnBase}payment/new_MatchWithElites_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },
        {
            index: 32,
            title: self.$t("rematch_txt"), //
            desc: self.$t("rematch_desc2"),
            icon: `${cdnBase}payment/05_Rematch_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },
        {
            index: 43,
            title: self.$t("dia_privacy_mode"),
            desc: self.$t("dia_privacy_mode_desc"),
            icon: `${cdnBase}payment/new_PrivacyMode_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },
        {
            index: 44,
            title: self.$t("date_concierge"),
            desc: self.$t("date_concierge_desc"),
            icon: `${cdnBase}payment/new_DateConcierge_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },
        {
            index: 30,
            title: self.$t("dia_profile_view"),
            desc: self.$t("dia_profile_view_desc"),
            icon: `${cdnBase}payment/02_ProfileSetup_diamond.png`,
            btn: {
                text: getDiamondTxt,
                type: "diamond",
            },
        },

        // {
        //     index: 46,
        //     title: self.$t("dia_date_coaching"),
        //     desc: self.$t("dia_date_coaching_desc"),
        //     icon: `${cdnBase}payment/new_ImageAndDateCoaching_diamond.png`,
        //     btn: {
        //         text: getDiamondTxt,
        //         type: "diamond",
        //     },
        // },
    ];
    //carousels
    // let allGolds = [{
    //     index: 47,
    //     title: "Plus all Gold Features",
    //     desc: `${self.$t("match_and_chat")}, ${self.$t("dia_profile_view")}, ${self.$t("dia_perfect_match")}, ${self.$t("rematch_txt")}, ${self.$t("activity_reports")}, ${self.$t("city_travel")}`,
    //     icon: `${cdnBase}payment/all_gold_icons.png`
    //     // icons: [
    //     //     `${cdnBase}payment/new_PerfectMatchFilters_gold.png`,
    //     //     `${cdnBase}payment/new_Rematch_gold.png`,
    //     //     `${cdnBase}payment/new_ActivityReports_gold.png`,
    //     //     `${cdnBase}payment/new_CityTravel_gold.png`,
    //     // ]
    // }]

    // if (allGold) {
    //     return [...carousels, ...allGolds]
    // }
    return carousels;

    // return [
    //     {
    //         index: 0,
    //         title: self.$t("dia_perfect_match"),
    //         desc: self.$t("dia_perfect_match_desc"),
    //         icon: `${cdnBase}payment/07_Diamond_PerfectMatchFilters.png`,
    //     },
    //     {
    //         index: 1,
    //         title: self.$t("profile_review_setup"),
    //         desc: self.$t("profile_review_setup_desc"),
    //         icon: `${cdnBase}payment/01_Diamond_Profile_Review_and_Setup.png`,
    //     },
    //     {
    //         index: 2,
    //         title: self.$t("match_verified_user"),
    //         desc: self.$t("match_verified_user_desc"),
    //         icon: `${cdnBase}payment/02_Diamond_Match_with_Verified_Members.png`,
    //     },
    //     {
    //         index: 3,
    //         title: self.$t("date_concierge"),
    //         desc: self.$t("date_concierge_desc"),
    //         icon: `${cdnBase}payment/03_Diamond_DateConcierge.png`,
    //     },
    //     {
    //         index: 4,
    //         title: self.$t("activity_reports"),
    //         desc: self.$t("activity_reports_desc"),
    //         icon: `${cdnBase}payment/05_Diamond_ActivityReport.png`,
    //     },
    //     {
    //         index: 5,
    //         title: self.$t("city_travel"),
    //         desc: self.$t("city_travel_desc"),
    //         icon: `${cdnBase}payment/08_Diamond_Citytravel.png`,
    //     },
    //     {
    //         index: 6,
    //         title: self.$t("chat_again"),
    //         desc: self.$t("chat_again_desc"),
    //         icon: `${cdnBase}payment/06_Diamond_ChatAgain.png`,
    //     },
    //     {
    //         index: 7,
    //         title: self.$t("match_boost"),
    //         desc: self.$t("match_boost_desc"),
    //         icon: `${cdnBase}payment/09_Diamond_MatchBoost.png`,
    //     },

    //----------


    // {
    //     index: 8,
    //     title: self.$t("dia_privacy_mode"),
    //     desc: self.$t("dia_privacy_mode_desc"),
    //     icon: `${cdnBase}payment/04_Diamond_Privacy_mode.png`,
    // },
    // {
    //     index: 9,
    //     title: self.$t("match_with_elites"),
    //     desc: self.$t("match_with_elites_desc"),
    //     icon: `${cdnBase}payment/10_Diamond_MatchWithElites.png`,
    // },
    // {
    //     index: 10,
    //     title: self.$t("dia_personal_matchmaker"),
    //     desc: self.$t("dia_personal_matchmaker_desc"),
    //     icon: `${cdnBase}payment/11_Diamond_PersonalMatchmaker.png`,
    // },
    // {
    //     index: 11,
    //     title: self.$t("image_date_coaching"),
    //     desc: self.$t("image_date_coaching_desc"),
    //     icon: `${cdnBase}payment/12_Diamond_image_date_coaching.png`,
    // },
    // ]
}
const platinumCarouselItems = (self) => {
    //(membership_level_id=3)
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";
    //payment/

    return [
        {
            index: 0,
            title: self.$t("chat_again"),
            desc: self.$t("chat_again_desc"),
            icon: `${cdnBase}payment/06_Platinum_ChatAgain.png`,
        },
        {
            index: 1,
            title: self.$t("match_boost"),
            desc: self.$t("match_boost_desc"),
            icon: `${cdnBase}payment/09_Platinum_MatchBoost.png`,
        },
        {
            index: 2,
            title: self.$t("profile_review_setup"),
            desc: self.$t("profile_review_setup_desc"),
            icon: `${cdnBase}payment/01_Platinum_Profile_Review_and_Setup.png`,
        },
        {
            index: 3,
            title: self.$t("match_verified_user"),
            desc: self.$t("match_verified_user_desc"),
            icon: `${cdnBase}payment/02_Platinum_Match_with_Verified_Members.png`,
        },
        {
            index: 4,
            title: self.$t("date_concierge"),
            desc: self.$t("date_concierge_desc"),
            icon: `${cdnBase}payment/03_Platinum_DateConcierge.png`,
        },
        {
            index: 5,
            title: self.$t("activity_reports"),
            desc: self.$t("activity_reports_desc"),
            icon: `${cdnBase}payment/05_Platinum_ActivityReport.png`,
        },
        {
            index: 6,
            title: self.$t("city_travel"),
            desc: self.$t("city_travel_desc"),
            icon: `${cdnBase}payment/08_Platinum_Citytravel.png`,
        },

    ]
}
const goldCarouselItems = (self, returnAll = true) => {
    //(membership_level_id=2)
    let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";
    //payment/
    let getGoldTxt = self.$t("get_gold");
    //   let getDiamondTxt = self.$t("get_diamond_txt");

    // if (!returnAll) {
    //     return [
    //         {
    //             index: 32,
    //             title: self.$t("rematch_txt"), //
    //             desc: self.$t("rematch_desc2"),
    //             icon: `${cdnBase}payment/new_Rematch_gold.png`,
    //             btn: {
    //                 text: getGoldTxt,
    //                 type: "gold",
    //             },
    //         },
    //         {
    //             index: 31,
    //             title: self.$t("dia_perfect_match"),
    //             desc: self.$t("dia_perfect_match_desc"),
    //             icon: `${cdnBase}payment/new_PerfectMatchFilters_gold.png`,
    //             btn: {
    //                 text: getGoldTxt,
    //                 type: "gold",
    //             },
    //         },
    //         {
    //             index: 34,
    //             title: self.$t("city_travel"),
    //             desc: self.$t("city_travel_desc"),
    //             icon: `${cdnBase}payment/new_CityTravel_gold.png`,
    //             btn: {
    //                 text: getGoldTxt,
    //                 type: "gold",
    //             },
    //         },
    //     ];
    // }

    let head = [




    ];

    let main = [
        {
            index: 28,
            title: self.$t("genuine_matches"),
            desc: self.$t("genuine_matches_desc"),
            icon: `${cdnBase}payment/14_ReceiveCustomMatches_Gold.png`,
            // icon: `${cdnBase}payment/match_and_chat_gold.png`,
            btn: {
                text: getGoldTxt,
                type: "gold",
            },
        },
        {
            index: 29,
            title: self.$t("match_and_chat"),
            desc: self.$t("match_and_chat_desc"),
            icon: `${cdnBase}red_design_2021/chat_with_matches_gold.png`,
            btn: {
                text: getGoldTxt,
                type: "gold",
            },
        },
        {
            index: 42,
            title: self.$t("match_boost"),
            desc: self.$t("match_boost_desc"),
            icon: `${cdnBase}payment/09_MatchBoost_gold.png`,
            btn: {
                text: getGoldTxt,
                type: "gold",
            },
        },
        // {
        //     index: 32,
        //     title: self.$t("rematch_txt"), //
        //     desc: self.$t("rematch_desc2"),
        //     icon: `${cdnBase}payment/new_Rematch_gold.png`,
        //     btn: {
        //         text: getGoldTxt,
        //         type: "gold",
        //     },
        // },
        {
            index: 31,
            title: self.$t("dia_perfect_match"),
            desc: self.$t("dia_perfect_match_desc"),
            icon: `${cdnBase}payment/new_PerfectMatchFilters_gold.png`,
            btn: {
                text: getGoldTxt,
                type: "gold",
            },
        },

        // {
        //     index: 30,
        //     title: self.$t("dia_profile_view"),
        //     desc: self.$t("dia_profile_view_desc"),
        //     icon: `${cdnBase}payment/profile_improvement_gold.png`,
        //     btn: {
        //         text: getGoldTxt,
        //         type: "gold",
        //     },
        // },
        // {
        //     index: 33,
        //     title: self.$t("activity_reports"),
        //     desc: self.$t("activity_reports_desc"),
        //     icon: `${cdnBase}payment/new_ActivityReports_gold.png`,
        //     btn: {
        //         text: getGoldTxt,
        //         type: "gold",
        //     },
        // },
        // {
        //     index: 34,
        //     title: self.$t("city_travel"),
        //     desc: self.$t("city_travel_desc"),
        //     icon: `${cdnBase}payment/new_CityTravel_gold.png`,
        //     btn: {
        //         text: getGoldTxt,
        //         type: "gold",
        //     },
        // },
        {
            index: 35,
            title: self.$t("profile_verify"),
            desc: self.$t("profile_verify_desc"),
            icon: `${cdnBase}payment/03_MatchAndChat_gold.png`,
            btn: {
                text: getGoldTxt,
                type: "gold",
            },
        },
    ];

    // if (returnAll) {
    //     return [...head, ...main]
    // }

    return main;

    // return [
    //     {
    //         index: 0,
    //         title: self.$t("profile_review_setup"),
    //         desc: self.$t("profile_review_setup_desc"),
    //         icon: `${cdnBase}payment/01_Gold_Profile_Review_and_Setup.png`,
    //     },
    //     {
    //         index: 1,
    //         title: self.$t("match_verified_user"),
    //         desc: self.$t("match_verified_user_desc"),
    //         icon: `${cdnBase}payment/02_Gold_Match_with_Verified_Members.png`,
    //     },
    //     {
    //         index: 2,
    //         title: self.$t("date_concierge"),
    //         desc: self.$t("date_concierge_desc"),
    //         icon: `${cdnBase}payment/03_Gold_DateConcierge.png`,
    //     },
    //     {
    //         index: 3,
    //         title: self.$t("activity_reports"),
    //         desc: self.$t("activity_reports_desc"),
    //         icon: `${cdnBase}payment/05_Gold_ActivityReport.png`,
    //     },
    //     {
    //         index: 4,
    //         title: self.$t("city_travel"),
    //         desc: self.$t("city_travel_desc"),
    //         icon: `${cdnBase}payment/08_Gold_Citytravel.png`,
    //     },
    // ]

}
export default {
    goldCarouselItems,
    diamondCarouselItems,
    currenyPriceFormat,
    checkEmail,
    checkPhoneMobile,
    checkMobile,
    checkMobile2,
    getDateStr,
    calcDays,
    parseDate,
    setLoginData,
    parseFirstLoadPage,
    membershipPhoto, membershipTextPhoto, membershipLogoTextPhoto, getMembershipNameById, getMembershipPhotoById, lsLogos,
    setLeftSec,
    renewStarterCarousel, renewPlusCarousel, renewDiamondCarousel,
    freeStarterCarousel, freePlusCarousel,
    mePageCarousels, membershipColor,
    guest2StarterCarousels, starter2GoldCarousels, silver2GoldCarousels,
    meStarterCarousel, meGoldCarousel,
    upgradeGoldCarousel, upgradeDiamondCarousel, upgradeDiamondCarouselForGold,
    device, divideNumber, base64, lshash, cityShortName, prettyChatTime
}